import { template as template_89a583bf0efb485e959fa3b61639eef2 } from "@ember/template-compiler";
const FKLabel = template_89a583bf0efb485e959fa3b61639eef2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_08c7f6a5d09745d297c0e73e7e915ffc } from "@ember/template-compiler";
const FKText = template_08c7f6a5d09745d297c0e73e7e915ffc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_67e07b2cbfc347cba30cc87ad8b6f3bd } from "@ember/template-compiler";
const FKControlMenuContainer = template_67e07b2cbfc347cba30cc87ad8b6f3bd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
